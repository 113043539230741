@import "src/colorVariables.scss";
.options-list {
  background-color: $lighter;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: fixed;
  // display: none;
  // max-height: 0;
  // overflow: hidden;
  // transition: transform 0.1s ease-out;
  transition: transform 0.2s cubic-bezier(0.2, 0.9, 0.3, 1.1);
  transform-origin: top;
  transform: scaleY(0);
  // width: 235px;
  color: black;
  cursor: default;
  border-radius: 2px;
  z-index: 3;

  &.custom-elements {
    width: unset;
    max-width: 420px;
    // max-height: 580px;
    overflow: auto;
    z-index: 15;
  }

  &.open {
    transform: scaleY(1);
  }

  &.padd {
    padding: 15px 0;
  }
  .background-color {
    .value {
      display: flex;
      gap: 8px;
      > div {
        height: 20px;
        width: 20px;
        border-radius: 2px;
        border: 1px solid rgba(162, 162, 162, 0.3);
        cursor: pointer;
      }
      .color1 {
        background-color: $background-color1;
      }
      .color2 {
        background-color: $background-color2;
      }
      .color3 {
        background-color: $background-color3;
      }
      .color4 {
        background-image: linear-gradient(
          -45deg,
          black,
          white,
          black,
          white,
          black,
          white,
          black,
          white,
          black,
          white,
          black,
          white,
          black
        );
        opacity: 0.3;
      }
      .selected {
        border: 2px solid rgba($color: $blue, $alpha: 1);
      }
    }
  }
  .options-empty {
    text-align: center;
    font-style: italic;
    color: $mid;
  }

  .option-in-list {
    position: relative;
    .clickable-area {
      padding: 5px 20px 5px 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text,
      .icon {
        display: inline-block;
        font-size: 16px;
      }

      .icon {
        width: 24px;
        text-align: center;
        padding-right: 10px;
        display: flex;
        justify-content: center;
      }

      .text {
        // white-space: break-spaces;
        white-space: nowrap;
        flex-grow: 1;
        pointer-events: none;
        padding-left: 12px;
      }

      .right-icon-roomer {
        width: 30px;
      }
    }

    .point-right {
      margin-left: 20px;
      margin-top: 0px;
      font-size: 14px;
      pointer-events: none;
    }
    .right-icon {
      margin-left: 10px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      &:hover {
        background-color: rgba($color: $mid, $alpha: 0.1);
      }
    }

    &:hover {
      background-color: $light;
    }
  }
  .title {
    // background-color: #F4F5F7;
    // text-align: center;
    margin-left: 56px;
    // height: 26px;
    font-size: 12px;
    line-height: 15px;
    color: $mid;
    opacity: 0.98;
    font-weight: 600;
  }
  .edit-module {
    height: 630px;
    width: 430px;
    display: flex;
    flex-direction: column;
    user-select: none;
    .top {
      background-color: $light;
      display: flex;
      justify-content: center;
      height: 26px;
      flex-shrink: 0;

      .top-choice {
        padding: 0 22px;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 1px;
        user-select: none;
        cursor: pointer;
        color: $mid;
        height: 100%;
        display: grid;
        place-items: center;

        &.active {
          color: black;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: unset;
      max-height: 604px;
      overflow: auto;
      padding-bottom: 40px;
      .media {
        padding: 0 20px;
        flex-wrap: wrap;
        position: relative;
        .medium {
          height: unset;
          flex-grow: 1;
          margin: 1px;
          cursor: grab;
          position: relative;
          max-width: 250px;
          .remove {
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            background-color: #ffffff;
            display: flex;
            padding: 5px;
            color: $red;
            cursor: pointer;
            opacity: 0.4;
            border-radius: 2px;
            transition: ease-out 0.2s;
            &:hover {
              opacity: 0.6;
            }
          }
          .one-media {
            height: unset;
            .upload-box {
              height: unset;
              .image-container {
                height: 110px;
              }
            }
          }
        }
      }

      .option {
        margin: 0 20px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba(162, 162, 162, 0.1);
        transition: ease-out 0.2s;
        &.description {
          // flex-direction: column;
          display: block;
          padding-top: 16px;
          .label {
            width: 100%;
          }
          .ck-content {
            background-color: $light;
            height: 150px;
          }
        }
        &.whole-click {
          cursor: pointer;
          &:hover {
            background-color: $lighter;
          }
        }
        .label {
          margin-left: 10px;
        }
        .value {
          margin-right: 10px;
          min-width: 125px;
          text-align: right;
          .css-2b097c-container {
            width: 210px;
            .css-yk16xz-control,
            .css-1pahdxg-control {
              border: none;
              .css-g1d714-ValueContainer {
                display: flex;
                justify-content: flex-end;
              }
              .css-1okebmr-indicatorSeparator {
                display: none;
              }
              .css-tlfecz-indicatorContainer,
              .css-1gtu0rj-indicatorContainer {
                padding-right: 0;
                > svg {
                  display: none;
                }

                &::before {
                  font-family: "Font Awesome 5 Pro";
                  font-weight: 100;
                  content: "\f078";
                  font-size: 16px;
                  visibility: visible;
                  color: black;
                }
              }
            }
          }
        }
      }
      .option.link {
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
      }
    }
  }
}
