@import "src/colorVariables.scss";
.sites-list {
  width: 0px;
  height: 100%;
  background-color: rgba(162, 162, 162, 0.1);
  box-shadow: inset -10px 0 20px -7px rgba(0, 0, 0, 0.1);
  transition: ease-out 0.75s;
  // position: relative;

  &.disabled {
    pointer-events: none;
    .the-list {
      // opacity: 0.35;
    }
  }
  .load-icon {
    position: absolute;
  }
  &.loaded {
    width: 255px;
    // position: static;
  }
  .the-list {
    overflow: auto;
    .divide-sites {
      padding-bottom: 30px;
      .wrap-sites {
        padding-top: 100px;
        .sites-headline {
          padding: 25px 0 25px 30px;
          font-size: 12px;
          font-weight: 600;
          overflow: hidden;
          color: $mid;

          white-space: nowrap;
        }

        .site {
          font-size: 16px;
          padding: 5px 30px;
          white-space: nowrap;
          cursor: pointer;
          background-color: transparent;
          transition: ease-in 0.1s;
          overflow: hidden;
          position: relative;
          &:hover {
            background-color: lightgray;
            box-shadow: inset -10px 0 20px -7px rgba(0, 0, 0, 0.1);
            .options-button {
              opacity: 1;
            }
          }
          .default-button {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
          .options-button {
            position: absolute;
            width: 20px;
            right: 10px;
            top: 50%;
            text-align: center;
            transform: translateY(-50%);
            border-radius: 2px;
            opacity: 0;
            transition: ease-in-out 0.1s;
            &:hover {
              background-color: $mid;
            }
          }
        }
        .site.selected {
          background-color: black;
          color: $light;
          box-shadow: -10px 0 20px inset rgba(255, 255, 255, 0.3);
        }
      }
      .wrap-sites:nth-child(2) {
        padding-top: 0;
      }
    }
  }
}
