@import "src/colorVariables.scss";

.title {
  color: $mid;
  opacity: 0.98;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: 4rem;
}

.categoriesContainer {
  padding-top: 1.5rem;

  /**
   * User input values.
   */
  --grid-layout-gap: 10px;
  --grid-layout-column-gap: 50px;
  --grid-column-count: 3;
  --grid-item--min-width: 100px;

  @media (max-width: 1680px) {
    --grid-column-count: 2;
    --grid-layout-gap: 100px;
  }
  @media (max-width: 1320px) {
    --grid-column-count: 1;
  }

  /**
   * Calculated values.
   */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  // grid-gap: var(--grid-layout-gap);
  grid-gap: var(--grid-layout-column-gap) var(--grid-layout-gap);
}

.catContainer {
  display: flex;
  gap: 1rem;

  @media (max-width: 1680px) {
    gap: 1rem;
  }
  @media (max-width: 1320px) {
    gap: 5rem;
  }

  .catTitle {
    width: 80px;
    color: $dark;
    line-height: 20px;
  }

  ul {
    display: flex;
    flex-direction: column;

    li:first-of-type {
      color: $dark;
      margin-top: 0;
    }
  }
}
