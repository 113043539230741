$smallPhone: "max-width: 660px";
$phone: "max-width: 880px";
$smallerDesktop: "max-width: 1240px";
$smallDesktop: "fit-content(1440px)";
$bigDesktop: "max-width: 2400px";

$smallDesktopMin: "min-width: 1440px";

$tablet: "max-width: 990px";
$bigDesktop: "max-width: 2400px";

$color-button-blue: rgb(0, 159, 255);

$color-background-gray: rgba(0, 0, 0, 0.03);

$background-color1: #ffffff;
$background-color2: #efefef;
$background-color3: #373737;

$lighter-lighter-blue: #ecf8ff;
$lighter-blue: #b3e2ff;
$light-blue: #80cfff;
$blue: #009fff;
$dark-blue: #008dff;
$darker-blue: #0072ff;

$lighter: #f9f9fa;
$light: #f5f5f5;
$mid: #a2a2a2;
$mid-between: rgba(162, 162, 162, 0.1);
$dark: #313131;

$text-color: #373737;
$grey-menu: #f4f5f7;

$green: #7ecf8f;
$orange: #f6ad4e;
$red: #f64e60;
$note: #fff59d;

$defaultBrightness: brightness(0.8);

$input-fill: rgba(162, 162, 162, 0.05);
