@import "src/colorVariables.scss";
.whatson-create-new {
  height: 100%;

  .close-event {
    position: absolute;
    right: 20px;
    top: 6px;
    font-size: 24px;
    cursor: pointer;
  }
  .header-display {
    display: flex;
    padding-top: 30px;
    border-bottom: 1px solid $light;
    height: 130px;
    // .whatson-step{
    //     display: inline-block;
    //     margin: 0 auto;
    //     cursor: pointer;
    //     position: relative;
    //     .w-bubble{
    //         transition: ease-in-out 0.2s;
    //         width: 58px;
    //         height: 58px;
    //         background-color: $light;
    //         border-radius: 29px;
    //         position: relative;
    //         margin: auto;
    //         margin-bottom: 10px;
    //         svg,.b-text{
    //             position: absolute;
    //             left: 50%;
    //             top: 50%;
    //             transform: translate(-50%,-50%);
    //             font-size: 24px;
    //         }
    //         .b-text{
    //             font-size: 12px;
    //         }
    //     }
    //     p{
    //         text-align: center;
    //         color: $mid;
    //         position: absolute;
    //         left: 50%;
    //         transform: translateX(-50%);
    //         white-space: nowrap;
    //     }
    // }
    // .whatson-step:hover{
    //     .w-bubble{
    //         background-color: $mid;
    //     }
    // }
    // .whatson-step.selected{
    //     .w-bubble{
    //         background-color: black;
    //         svg,.b-text{
    //             color: white;
    //         }
    //     }
    //     p{
    //         color: black;
    //     }
    // }
    // .whatson-step.bubble-error{
    //     .w-bubble{
    //         box-shadow: 0 0 15px $red;
    //     }
    // }
  }
  .blur-screen {
    position: fixed;
    background-color: rgba(black, 0.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  .blur-screen.hidden {
    display: none;
  }
}

.with-tooltip,
.with-tooltip-2 {
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.with-tooltip-2 {
  margin-bottom: 16px;

  justify-content: flex-start;

  gap: 10px;

  &.no-margin {
    margin-bottom: 0;
  }
}

.tooltip-icon {
}

.tooltip-card {
  padding: 15px;
  max-width: 450px;

  font-size: 16px;

  text-align: justify;
}

.new-event {
  height: 100%;
  .w-content {
    height: calc(100% - 139px);
    overflow: auto;

    .image-upload-block {
      height: 200px;
      max-height: 200px;
      cursor: pointer;
      .upload-box {
        height: 100%;
      }
      .image-container {
        margin: auto;
        border: 1px solid rgba(0, 0, 0, 0.06);
        height: 100%;
        position: relative;
        max-height: 400px;
        .before-image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 64px;
          color: $blue;
        }
        .pen {
          position: absolute;
          right: 15px;
          top: 15px;
          //color: $mid;
          opacity: 0.5;
        }
        img {
          width: 298px;
          height: 190px;
          object-fit: contain;
        }
      }
    }

    .uploaded-images {
      .one-image {
        position: relative;
        margin-top: 0;
        margin-bottom: 40px;
        box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);
        // transition: ease-in-out 0.5s;
        img {
          width: 100%;
          // height: 200px;
          aspect-ratio: 16 / 9;
          object-fit: cover;
          opacity: 1;
          transition: opacity 0.2s;
          // pointer-events: none;
        }
        .drag-element {
          position: absolute;
          height: 200px;
          width: 100%;
          left: 0;
          top: 0;
          cursor: move;
        }
        .remove-image {
          position: absolute;
          top: 3px;
          right: 25px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }
        .move-image {
          position: absolute;
          top: 3px;
          right: 45px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }
        .text-wrap {
          margin-top: 15px;
          .flag {
            object-fit: contain;
            display: inline-block;
            width: 30px;
            height: unset;
            opacity: 1;
            margin: 5px 0 2px;
            pointer-events: none;
          }
        }
        .text-en {
          top: 30px;
        }
        .text-fo {
          top: 115px;
        }
      }
      .one-image:hover {
        img {
          opacity: 0.6;
        }
        .remove-image {
          opacity: 1;
        }
        .move-image {
          opacity: 1;
        }
        .text-wrap {
          opacity: 1;
        }
      }
      .one-image.dragging {
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        // display: none;

        // max-width: 0px;
        opacity: 0;
        overflow: hidden;
        // padding: 0;
      }

      .one-image.dragging-on {
        background-color: $light;
      }
    }

    .new-content.one {
      .language-box.faroese:not(.active),
      .language-box.english:not(.active) {
        // display: none;
        .headline,
        .small-description,
        .description {
          opacity: 0.1;
        }
        // .text{
        // }
      }

      .language-box {
        margin: auto;
        display: inline-block;
        // padding-left: 0;
        // padding-right: 50px;
        img.flag {
          width: 30px;
        }
        .l-include {
          margin-left: 20px;
        }
        .headline,
        .small-description,
        .description {
          margin-top: 50px;
        }
        .headline {
          margin-top: 30px;
        }
        &.english {
          border-right: 1px solid $light;
          padding-right: 50px;
          @media (max-width: 992px) {
            border: none;
            padding-right: 0px;
          }
        }
        &.faroese {
          padding-left: 50px;
          @media (max-width: 992px) {
            padding-left: 0;
            padding-top: 60px;
          }
        }
        // .vfi-input-text input, textarea{
        //         border-radius: 0;
        // }
      }
    }

    .enable_editing {
      cursor: pointer;
      width: 100%;
      margin-left: 28%;
      color: orange;
      svg {
        margin-left: 5px;
        font-size: 12px;
      }
      p {
        display: inline-block;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }

    .enable_editing_checkbox {
      width: 100%;
      p {
        color: orange;
        display: inline-block;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 5px;
      }
    }

    .text-include {
      margin-right: 5px;
    }

    .new-content {
      padding: 43px 60px;
      h1 {
        font-size: 30px;
        font-weight: 600;
      }

      .btn_current_submitted {
        width: 100%;
        margin-bottom: 10px;
        .btn {
          width: 115px;
          font-size: 12px;
          padding: 0;
          margin: 0;
          margin-right: 15px;
          background-color: $light;
          cursor: pointer;
          color: black;
        }
        .choosen {
          color: white;
          background-color: orange;
        }
      }
      .fill-form {
        display: flex;
        margin-top: 50px;

        .language-box {
          margin: auto;
          display: inline-block;
          padding-left: 0;
          img.flag {
            width: 30px;
          }
          .l-include {
            margin-left: 20px;
          }
          .headline,
          .slug-url-wrap,
          .small-description,
          .description {
            margin-top: 50px;
            // align-items: center;
            // padding-right: 100px;
            .text {
              padding-top: 15px;
            }
          }
          .headline {
            margin-top: 30px;
          }
          .small-description {
            .ck-editor {
              .ck-content {
                height: 130px;
              }
            }
          }
          .description {
            .ck-editor {
              .ck-content {
                height: 260px;
              }
            }
          }
        }
      }
    }

    .new-content.two {
      .event-categories {
        list-style: none;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        white-space: nowrap;
        margin: 60px auto 20px 0;
        padding: 0;
        width: 60%;
        .group {
          width: 30%;
          margin-bottom: 6px;
          font-size: 15px;
          label {
            user-select: none;
            cursor: pointer;
            margin-bottom: 0;
            position: relative;
            .vfi-checkbox-2 {
              margin-right: 12px;
            }
            .text {
              display: inline-block;
              position: absolute;
              bottom: 2px;

              .fo-category-extra,
              .en-category-extra {
                opacity: 0.5;

                font-style: italic;

                font-size: 12px;
              }
            }
          }

          transition: 0.2s;

          &.disabled {
            pointer-events: none;

            color: rgba(0, 0, 0, 0.5);

            filter: grayscale(1);
          }
        }
      }
      .add-category {
        display: flex;
        // justify-content: center;
        align-items: center;
        .label {
          margin-right: 20px;
        }
        .add-button {
          color: $green;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }

    .new-content.three {
      .top-title {
        margin-top: 40px;
        margin-bottom: 10px;
        font-size: 15px;
        .question-info {
          margin-left: 10px;
        }
      }
      .question-info {
        .question-box {
          left: 250px;
          .point-up {
            left: 29.5%;
          }
        }
      }
      .dates {
        // width: 60%;
        margin: auto;
      }
    }

    .new-content.four {
      .is-free {
        label {
          cursor: pointer;
        }
        position: relative;
        .text {
          position: absolute;
          left: 30px;
        }
      }
    }

    .new-content.five {
      .edit-contents {
        margin-top: 60px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;

        // width: 80%;

        .text-wrap {
          margin-top: 10px;
        }

        .extra-video-link-text {
          margin-top: 30px;
          font-size: 18px;
        }

        .add-extra-video-link {
          width: 50%;
          @media (max-width: 1200px) {
            width: 100%;
          }
          .add-extra-video-link-wrap {
            // background-color: $blue;
            // display: inline-block;
            // padding: 10px 100px;
            // margin-bottom: 20px;
            // color: white;
            // font-weight: 600;
            // text-align: center;
            // cursor: pointer;
            // user-select: none;
            text-align: center;
            cursor: pointer;
            user-select: none;
            width: 100px;
            margin: auto;
            span.text {
              margin-right: 8px;
            }
            &:hover {
              span.text {
                text-decoration: underline;
              }
            }
          }
        }
        .video-links {
          display: grid;
          grid-template-columns: 90% 10%;
          margin-bottom: 20px;
          width: 50%;
          @media (max-width: 1200px) {
            width: 100%;
          }
          .trash-icon {
            text-align: center;
            position: relative;
            svg {
              position: absolute;
              top: 10%;
              left: 25%;
              transform: translate(-50%, -50%);
              font-size: 12px;
              cursor: pointer;
            }
          }
        }

        .add-new-media-content {
          width: 50%;
          .add-new-media {
            display: inline-block;
            background-color: #009fff;
            color: white;
            vertical-align: middle;
            font-size: 17px;
            font-weight: 600;
            height: 50px;
            width: 160px;
            position: relative;
            cursor: pointer;
            margin-left: 5px;
            .text {
              width: 160px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
            }
          }
          .upload-box {
            margin-top: 30px;
            height: 150px;
            border: 2px dashed rgba(162, 162, 162, 0.5);
            position: relative;
            overflow: hidden;
            transition: height 0.5s;
            .text-content {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              .first {
                text-align: center;
                font-size: 18px;
                font-weight: 600;
              }
              .second {
                text-align: center;
                font-size: 14px;
                color: #a2a2a2;
              }
            }
          }

          .upload-box.hidden {
            height: 0;
            border: 0;
          }
        }
      }
    }

    .new-content.six {
      display: flex;
      flex-direction: column;
      height: 100%;
      .in-list,
      .not-in-list {
        transition: opacity 0.5s;
        opacity: 1;
        // flex-grow: 1;

        .more-values {
          //     display: flex;
          //     .region,.zip-code{
          //         width: 50%;
          //         .css-2b097c-container{
          //             width: 90%;
          //         }
          //     }
          margin-top: 40px;
          p {
            margin-bottom: 5px;
            font-size: 15px;
          }
        }
        .search-box {
          // display: none;
          &.error {
            .vfi-input-text {
              border: 2px solid $red;
              border-radius: 2px;
            }
          }
        }
      }

      .in-list {
        // flex-grow: 1;
      }

      .max-capacity {
        opacity: 1;
        transition: ease-in-out 0.2s;
        max-width: 675px;
        padding-bottom: 20px;
        p {
          margin-top: 20px;
          margin-bottom: 5px;
          font-size: 15px;
        }
        &.hidden {
          pointer-events: none;
          opacity: 0;
        }
      }

      .hidden {
        opacity: 0;
        pointer-events: none;
        transform: scaleY(0);
        height: 0;
      }

      .on-list-determine-parent {
        display: flex;
      }

      .on-list-determine {
        display: flex;
        width: max-content;
        .not-in-list {
          font-size: 15px;
          margin: 0 8px 0 6px;
        }
      }

      .question-info {
        display: inline-block;
      }

      .choose-place-text {
        font-size: 15px;
        margin-bottom: 10px;
      }
    }

    .new-content.seven {
      .who-is-host {
        label {
          display: flex;
          align-items: center;
          margin: 0;
        }
        .text {
          margin-left: 10px;
          font-size: 15px;
        }
      }
      .edit-contents {
        opacity: 1;
        transition: opacity 0.5s;
        .contact-content {
          display: flex;
          align-items: center;
          margin-top: 20px;
          p {
            margin: 0;
            width: 200px;
            font-size: 15px;
          }
          .input {
            width: 415px;
          }
        }
      }

      .edit-contents.hidden {
        user-select: none;
        opacity: 0;
        pointer-events: none;
      }
    }

    .finnished {
      .new-edit {
        margin-top: 60px;
        .final-text {
          display: flex;
          //align-items: center;
          justify-content: space-around;
          width: 60%;
          margin: auto;
          .wooh {
            color: $blue;
            font-size: 75px;
            width: 20%;
          }
          p {
            font-size: 22px;
            font-weight: 600;
            color: $mid;
            width: 50%;
          }
          margin-bottom: 40px;
        }
      }
    }

    .new-content.eight {
      .new-edit {
        margin-top: 60px;
        .final-text {
          display: flex;
          //align-items: center;
          justify-content: space-around;
          width: 60%;
          margin: auto;
          .wooh {
            color: $blue;
            font-size: 75px;
            width: 20%;
          }
          p {
            font-size: 22px;
            font-weight: 600;
            color: $mid;
            width: 50%;
          }
          margin-bottom: 40px;
        }
        .post-button {
          background-color: $blue;
          width: min-content;
          margin: auto;
          padding: 10px 50px;
          color: white;
          font-weight: 600;
          font-size: 28px;
          cursor: pointer;
          user-select: none;
          transition: ease-in-out 0.2s;
        }
        .post-button:hover {
          background-color: $light-blue;
        }
      }
      h3 {
        margin-top: 60px;
        text-align: center;
      }
      p {
        text-align: center;
      }
      .goose-image {
        margin-top: 80px;
        display: flex;
        justify-content: center;
        height: 400px;
      }
    }
  }
}
