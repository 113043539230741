


.headline-top {
    height: 24px;

    font-size: larger;
    font-weight: 700;

    text-overflow: clip;

    // padding: 40px 15px 0 25px;
    margin-bottom: 0;
}