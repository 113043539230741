@import "src/colorVariables.scss";
.media-content,
.overlay {
  height: 100%;
  overflow-y: auto;

  .media-display-parent {
    height: 100%;
    display: flex;

    .drag-on-screen {
      background-color: rgba($color: $blue, $alpha: 0.5);
      border: 5px dashed $blue;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      visibility: visible;
      opacity: 1;
      transition: ease-in-out 0.2s;
      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
      section {
        height: 100%;
        div {
          height: 100%;
        }
        .upload-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-shrink: 1;
          font-size: 24px;
          .content {
            pointer-events: none;
            display: block;
            width: unset;
            background-color: rgba($color: #ffffff, $alpha: 0.5);
            padding: 20px 30px;
            border-radius: 2px;
            height: unset;
            flex-grow: 0;
            text-align: center;
            .text {
              height: unset;
            }
          }
        }
      }
    }
    .media-display {
      padding: 57px 0px 0 0px;
      position: relative;
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;

      .add-new-media-parent {
        display: inline-block;
        width: 50%;
        text-align: right;
        .selected-options {
          display: inline-block;
        }
        .edit-selected,
        .delete-selected,
        .insert-selected,
        .add-new-media {
          display: inline-block;
          background-color: #009fff;
          color: white;
          vertical-align: middle;
          font-size: 17px;
          font-weight: 600;
          height: 50px;
          width: 160px;
          position: relative;
          cursor: pointer;
          margin-left: 5px;
          &.hidden {
            visibility: hidden;
          }
          .text {
            width: 160px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
          }
        }

        .edit-selected {
          background-color: $green;
        }
        .delete-selected {
          background-color: $red;
        }
        .insert-selected {
          background-color: black;
        }
      }

      .upload-media-parent.hidden {
        visibility: hidden;
      }

      .header-panel {
        padding: 0 40px;
        margin-bottom: 30px;
        .header-title {
          display: inline-block;
          width: 50%;
          h1 {
            font-size: 30px;
            opacity: 0.98;
            font-weight: bold;
          }
        }

        .upload-box {
          margin-top: 30px;
          height: 150px;
          border: 2px dashed rgba(162, 162, 162, 0.5);
          position: relative;
          overflow: hidden;
          transition: height 0.5s;
          .text-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .first {
              text-align: center;
              font-size: 18px;
              font-weight: 600;
            }
            .second {
              text-align: center;
              font-size: 14px;
              color: #a2a2a2;
            }
          }
        }

        .upload-box.hidden {
          height: 0;
          border: 0;
        }

        .search-parent {
          display: inline-block;
          width: 50%;
          margin-top: 20px;
          .search {
            position: relative;
            width: 50%;
            input {
              //background-color: rgba(162,162,162,0.1);
              //border-radius: 2px;
              //border: 0;
              //padding: 12px 6px;
              width: 100%;

              ::placeholder {
                font-size: 2px;
              }
            }
            .magnifying-glass {
              position: absolute;
              top: 45%;
              right: 12px;
              transform: translateY(-50%);
              pointer-events: none;
            }
          }
        }

        .all-sort-and-filter {
          display: inline-block;
          width: 50%;
          text-align: right;
          margin-top: 20px;
          .image-show,
          .list-show,
          .filter-button {
            cursor: pointer;
            display: inline-block;
            margin-left: 35px;
            padding: 5px 10px;
            transition: ease-in-out 0.5s;
            border-radius: 2px;
            .icon {
              margin: auto;
            }
          }

          .image-show.selected,
          .list-show.selected {
            color: #009fff;
          }

          .filter-button {
            padding: 9px 15px 9px 20px;
            border: 1px solid transparent;
            border-radius: 20px;
            user-select: none;
            background-color: $blue;
            color: white;
            .slide-icon {
              margin-left: 15px;
            }
          }
          .filter-button.hidden {
            padding: 9px 15px 9px 20px;
            border: 1px solid rgba(162, 162, 162, 0.1);
            border-radius: 20px;
            user-select: none;
            background-color: white;
            color: black;
            cursor: pointer;
            .slide-icon {
              margin-left: 15px;
            }
          }
          .filter-button.hidden {
            padding: 9px 15px 9px 20px;
            border: 1px solid rgba(162, 162, 162, 0.1);
            border-radius: 20px;
            user-select: none;
            cursor: pointer;
            .slide-icon {
              margin-left: 15px;
            }
          }
        }
        .filter-panel {
          height: 210px;
          transition: ease-in-out 0.5s;
          overflow: hidden;
          background-color: $input-fill;
          margin-top: 10px;
          padding: 15px;
          opacity: 1;
          width: 100%;

          .media-type {
            vertical-align: top;
            display: inline-block;
            margin-right: 40px;
          }
        }
        .filter-panel.hidden {
          // margin-top: 0;
          //height: 0;
          //visibility: hidden;
          // padding: 0 15px 0 15px;
          // opacity: 0;
        }
      }

      .media-edit-many {
        padding: 10px;
        .close-edit-all {
          float: right;
          cursor: pointer;
        }
        .add-new-media-parent {
          width: 100%;
        }
        .selected-images-wrap {
          margin-top: 40px;
          height: 148px;
          // overflow: scroll;
          overflow-x: auto;
          white-space: nowrap;
          padding: 15px;
          .one-image {
            display: inline-block;
            margin-right: 5px;
            position: relative;
            cursor: pointer;
            &:hover {
              .image-choose {
                background-color: rgba(255, 255, 255, 0.5);
              }
            }
            .close-image {
              position: absolute;
              right: 5px;
              z-index: 1;
            }
            .image-choose {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              transition: ease-in-out 0.2s;
            }
            .gpx-icon {
              background-color: $light;
              display: inline-block;
              justify-content: center;
              vertical-align: middle;
              text-align: center;
              .inside-gpx {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              svg {
                margin-top: 3px;
                font-size: 18px;
              }
              p {
                font-size: 10px;
                margin: 0;
              }
            }
            img,
            .gpx-icon {
              height: 100px;
              width: 133px;
              object-fit: cover;
            }
            .chosen {
              // opacity: 0.5;
              box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.6);
              opacity: 0.8;
            }
          }
        }
      }

      .error-upload-message {
        padding-left: 40px;
        color: $red;
      }

      .media-list {
        flex: 1;
        padding-bottom: 20px;
        padding-left: 40px;
        padding-right: 40px;
        height: calc(100% - 184px);
        overflow: auto;
        min-height: 500px;
        .media-table {
          width: 100%;
          th {
            font-size: 14px;
            font-weight: 600;
            color: #a2a2a2;
            opacity: 0.98;
            padding: 10px 0;
            user-select: none;
            transition: ease-in-out 0.2s;
            svg {
              margin-left: 9px;
            }
          }
          th.name,
          th.author,
          th.locations,
          th.file-size,
          th.date,
          th.hearted {
            cursor: pointer;
          }
          th.hearted {
            text-align: center;
          }
          th:hover {
            color: #626262;
          }
          th.sorted-by {
            color: black;
          }
          th.favourite {
            text-align: center;
          }
          th.check-select {
            text-align: center;
            label {
              margin: 0;
              padding: 0 0 0 10px;
            }
          }
          tbody {
            tr {
              &.media-content:hover {
                td {
                  background-color: $light;
                }
              }
              // td {
              //   padding: 10px 0;
              //   border-top: 1px solid rgba(162, 162, 162, 0.1);
              // }
              td.file {
                display: flex;
                align-items: center;
                max-width: 500px;
                min-width: 400px;
                cursor: pointer;
                padding: 0;
                .td-img-container {
                  height: 58px;
                  display: flex;
                  align-items: center;
                  width: 70px;
                  img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    // vertical-align: middle;
                  }
                }
                .upload-progress {
                  display: inline-block;
                  vertical-align: middle;
                  height: 40px;
                  width: 70px;
                  background-color: lightgray;
                }
                .gpx-icon {
                  background-color: $light;
                  display: inline-block;
                  width: 70px;
                  height: 40px;
                  vertical-align: middle;
                  text-align: center;
                  svg {
                    margin-top: 3px;
                    font-size: 18px;
                  }
                  p {
                    font-size: 10px;
                  }
                }
                .file-info {
                  display: inline-block;
                  vertical-align: middle;
                  margin-left: 20px;
                  padding: 10px 0;
                  .name {
                    margin-bottom: 3px;
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  .name.progress-bar {
                    background-color: transparent;
                    margin-bottom: 0;
                    height: 22px;
                    display: inline-block;
                    progress {
                      height: 5px;
                      border: none;
                      vertical-align: bottom;
                      margin-bottom: 2px;
                    }
                  }
                  .file-name {
                    font-size: 14px;
                    color: #a2a2a2;
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  .file-name.in-progress {
                    font-size: 12px;
                    color: #a2a2a2;
                    text-overflow: ellipsis;
                    width: 200px;
                    //text-overflow: clip ellipsis clip 0 3ch;
                    overflow: hidden;
                  }
                }
                .video-icon {
                  display: inline-block;
                  vertical-align: middle;
                  position: relative;

                  height: 58px;
                  display: flex;
                  align-items: center;
                  width: 70px;

                  img {
                    width: 100%;
                  }
                  .snapshot-generator {
                    display: none;
                  }
                  .play-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 28px;
                    color: rgba($color: #ffffff, $alpha: 0.75);
                  }
                  .play-circle {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 28px;
                    color: rgba($color: #000000, $alpha: 0.25);
                  }
                  .react-thumbnail-generator {
                    width: 100%;
                    height: 100%;
                    img {
                      width: 100%;
                      height: 100%;
                      pointer-events: none;
                      object-fit: contain;
                    }
                  }
                }
              }
              td.author {
                max-width: 120px;
                min-width: 100px;
                .author-name {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 95%;
                  font-size: 15px;
                  color: $dark;
                }
              }
              td.location {
                min-width: 100px;
                max-width: 120px;
                .location-sites {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 95%;
                  font-size: 15px;
                  color: $dark;
                }
              }
              td.size {
                min-width: 100px;
                max-width: 120px;

                .size-mb,
                .size-dimensions {
                  .location-sites {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 95%;
                  }
                }
                .size-dimensions {
                  font-size: 14px;
                  color: #a2a2a2;
                }
              }
              td.date {
                font-size: 15px;
                color: $dark;
                min-width: 130px;
              }
              td.favourite {
                text-align: center;
                svg {
                  cursor: pointer;
                }
                .hearted {
                  color: $blue;
                }
              }
              td.check-select {
                text-align: center;
                label {
                  margin: 0;
                  padding: 0 0 0 10px;
                }
              }
            }
          }
        }
      }

      .media-box {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 2px;
        padding-bottom: 5px;
        justify-content: flex-start;
        overflow: auto;
        .media {
          display: inline-block;
          overflow: hidden;
          flex-grow: 1;
          flex-shrink: 0;
          position: relative;
          max-width: 500px;
          margin: auto;
          .uploading {
            margin-top: 40%;
            progress {
              height: 5px;
              border: none;
              vertical-align: bottom;
              margin-bottom: 2px;
            }
          }

          .image-container {
            width: inherit;
            display: flex;
            opacity: 1;
            transition: ease-in-out 0.4s;
            iframe {
              width: 100%;
              height: 400px;
            }
            img {
              height: 200px;
              box-sizing: content-box;
              object-fit: cover;
              padding: 1px;
              max-width: 500px;
              min-width: 120px;
              flex-grow: 1;
            }
            .video-icon {
              display: inline-block;
              vertical-align: middle;
              position: relative;
              width: 100%;
              height: 200px;
              .snapshot-generator {
                display: none;
              }
              .play-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 64px;
                color: rgba($color: #ffffff, $alpha: 0.75);
              }
              .play-circle {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 64px;
                color: rgba($color: #000000, $alpha: 0.25);
              }
              .react-thumbnail-generator {
                width: 100%;
                height: 100%;
                img {
                  width: 100%;
                  height: 100%;
                  pointer-events: none;
                }
              }
            }
            .gpx-icon {
              text-align: center;
              height: 200px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: $light;
              //width: -webkit-fill-available;
              flex-basis: 100%;
              width: 200px;
              padding: 20px;
              svg {
                width: 100%;
              }
              p {
                padding-top: 20px;
              }
            }
          }
          .image-container.selected {
            opacity: 0.5;
          }

          .select-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 18px;
            font-weight: 600;
            color: white;
            padding: 14px 15px 13px 16px;
            border-radius: 2px;
            cursor: pointer;
            transition: ease-in-out 0.4s;
            color: rgba($color: #ffffff, $alpha: 0);
            background-color: rgba($color: $dark, $alpha: 0);
            span {
              user-select: none;
            }
          }

          .select-button.selected {
            color: rgba($color: #ffffff, $alpha: 1);
            background-color: rgba($color: $dark, $alpha: 1);
          }

          .trash-icon {
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 4px 10px 5px;
            border-radius: 2px;
            transition: ease-in-out 0.4s;
            color: rgba($color: #ffffff, $alpha: 0);
            background-color: rgba($color: #000, $alpha: 0);
            font-size: 18px;
            cursor: pointer;
          }

          .media-box-details {
            background-color: rgba($color: #000000, $alpha: 0.5);
            position: absolute;
            bottom: 0;
            width: calc(100% - 2px);
            left: 1px;
            .file-name,
            .size-mb {
              color: white;
              margin: 0 10px;
            }
          }
        }
        .media:hover {
          .select-button {
            color: rgba($color: #ffffff, $alpha: 1);
            background-color: rgba($color: $dark, $alpha: 1);
          }
          .trash-icon {
            color: #ffffff;
            background-color: rgba($color: #000, $alpha: 0.25);
          }
        }

        .media.uploading {
          background-color: lightgray;
          text-align: center;
          .upload-content {
            margin-top: 25%;
            display: inline-block;
            vertical-align: middle;
            progress {
              height: 5px;
              border: none;
              vertical-align: bottom;
              margin-bottom: 2px;
            }
          }
        }
      }

      #media-settings {
        height: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        .settings-header {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-grow: 5;
          margin: 20px 0 30px 0;
          .header-menu {
            font-size: 18px;
            width: 20px;
            margin-left: 20px;
            color: black;
            transition: color 0.2s;
            cursor: pointer;
          }
          .header-menu:hover {
            color: gray;
          }
        }

        .settings-body {
          flex-grow: 40;
          display: flex;
          height: 200px;
          .image-info {
            //flex-grow: 2;
            flex: 1 1 66%;
            .image-container {
              padding-right: 40px;
              display: flex;
              justify-content: center;
              iframe {
                width: 100%;
                height: 400px;
              }
              img {
                height: 400px;
                width: 100%;
                object-fit: contain;
                user-select: none;
                pointer-events: none;
                background-color: $light;
              }

              .video-container {
                width: unset !important;
              }
            }
            .image-info {
              margin-top: 30px;
              .name {
                font-size: 16px;
              }
              .date {
                font-size: 14px;
                color: #a2a2a2;
              }
            }
            .image-size {
              margin-top: 30px;
              .storage-size {
                font-size: 16px;
              }
              .dimensions {
                font-size: 14px;
                color: #a2a2a2;
              }
            }
          }
          .image-edit-labels {
            flex: 1 1 33%;

            .image-edit-labels-header {
              th {
                padding: 0 27px 16px 27px;
                font-weight: 400;
                font-size: 15px;
                border-bottom: 1px solid rgba(162, 162, 162, 0.3);
              }
              th.option {
                cursor: pointer;
              }
              th.selected {
                border-bottom: 1px solid black;
                color: black;
              }
            }
            .seo-table {
              margin-top: 30px;
              width: 100%;

              tbody {
                width: 374px;
                display: inline-block;
              }
              span.label {
                color: $dark;
                font-size: 16px;
              }
              tr {
                display: flex;
                // display: inline-block;
                width: 100%;
                td {
                  padding-bottom: 25px;
                  vertical-align: top;
                  width: 264px;
                  position: relative;

                  &.file-name-td {
                    padding-bottom: 10px;
                  }

                  .vfi-text-area {
                    min-width: unset;
                  }

                  .vfi-text-area textarea {
                    height: 150px;
                  }

                  .vfi-input-text {
                    // width: 200px;
                  }
                  .link-slug {
                    display: flex;

                    color: $mid;
                    font-size: 14px;
                    padding-left: 18px;
                    padding-top: 4px;
                    width: 100%;
                    height: 100%;
                    max-height: 25px;
                    max-width: 262px;
                    overflow: hidden;
                  }
                }
                td.info {
                  display: flex;
                  width: 110px;
                  padding-top: 18px;
                  padding-right: 5px;

                  .question-info {
                    margin-right: 5px;
                  }
                }
                td.info.text-area-info {
                  align-items: flex-start;
                  padding-top: 26px;
                  .label {
                  }
                }
              }
            }

            .copy-image-url {
              position: absolute;
              right: 10px;
              top: 12px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .location {
              .copy-link {
                position: relative;
                background-color: $lighter;
                margin-top: 20px;
                padding: 15px;
                p {
                  margin-bottom: 2px;
                }
                .link {
                  cursor: pointer;
                  width: fit-content;
                  background-color: $light;
                  padding: 5px;
                  border-radius: 2px;
                  transition: ease-in-out 0.2s;
                  //max-width: 350px;
                  width: 100%;
                  .link-text {
                    width: 95%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    vertical-align: middle;
                  }
                }
                .link:hover {
                  background-color: $mid;
                }
                .popup {
                  position: absolute;
                  transition: ease-in-out 0.5s;
                  color: black;
                  top: 0;
                  // right: 0;
                  // bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border-radius: 2px;
                  background-color: rgba($color: white, $alpha: 0.5);
                  opacity: 1;
                  .text {
                    //position: absolute;
                    // top: 50%;
                    // left: 50%;
                    // transform: translate(-50%,-50%);
                    text-align: center;
                    color: $green;
                    font-size: 18px;
                    font-weight: bold;
                  }
                }
                .popup.hidden {
                  color: transparent;
                  visibility: hidden;
                  opacity: 0;
                }
              }

              .text-over {
                margin-top: 20px;
              }
              .site-locations {
                overflow: auto;
              }

              .site {
                font-weight: 600;
                margin-bottom: 5px;
                .gray {
                  color: $mid;
                }
                svg {
                  margin-left: 10px;
                  color: red;
                  cursor: pointer;
                }
              }

              .empty {
                font-style: italic;
                color: lightgrey;
                font-weight: normal;
              }

              .add-location-button {
                margin-top: 20px;
                background-color: $green;
                color: white;
                padding: 5px 10px;
                font-weight: bold;
                float: right;
                cursor: pointer;
                user-select: none;
              }
            }
          }
        }

        .settings-right-content {
          max-width: 374px;
          height: calc(100% - 40px);
          overflow: auto;
          .media-langauges {
            margin-top: 5px;
            display: flex;

            .one-lang,
            .add-lang {
              padding: 15px;
              width: 50px;
              font-size: 14px;
              line-height: 10px;
              user-select: none;
              cursor: pointer;
              color: $mid;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              transition: ease-in-out 0.2s;

              &:hover {
                background-color: $light;
              }
              &.active {
                color: black;
                font-weight: 600;
              }
            }
          }
          .saved {
            opacity: 1;
            transition: none;
            color: $green;
            font-weight: 600;
          }
          .saved.hidden {
            opacity: 0;
            transition: ease-in-out 2s;
          }
          .buttons {
            display: flex;
            justify-content: flex-end;
            button.insert-button,
            button.update-button {
              // float: right;
              background-color: black;
              color: white;
              border: none;
              border-radius: 2px;
              width: 160px;
              height: 50px;
              font-size: 17px;
              font-weight: 600;
              margin-top: 20px;
              margin-left: 10px;
              &.saved {
                background-color: $green;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                  margin-left: 5px;
                }
              }
            }
            button:disabled {
              background-color: $light;
            }
          }
        }

        .settings-footer {
          flex-grow: 25;
          width: 100%;
          display: flex;
          flex-direction: column;

          padding-right: 40px;
          margin-top: 36px;
          .image-info {
            margin: 0 auto;

            width: 226px;
            .name {
              font-size: 16px;
            }
            .date {
              font-size: 14px;
              color: #a2a2a2;
            }
          }
          .image-size {
            margin: 30px auto 0 auto;

            width: 226px;
            .storage-size {
              font-size: 16px;
            }
            .dimensions {
              font-size: 14px;
              color: #a2a2a2;
            }
          }
          .footer-buttons {
            margin: auto;
            display: flex;
            justify-content: center;
            width: 200px;
            margin-top: 32px;
            .button-element {
              display: inline-block;
              margin: 0 20px 0 20px;
              cursor: pointer;
              .icon {
                transition: color 0.25s;
                color: #a2a2a2;
                font-size: 24px;
                text-align: center;
              }
              .text {
                transition: color 0.25s;
                color: #a2a2a2;
                font-size: 14px;
              }
            }
            .button-element:hover {
              .icon,
              .text {
                color: #626262;
              }
            }

            .download-element {
              position: relative;
              display: inline-block;
              .download-menu {
                display: none;
                position: absolute;
                background-color: white;
                box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
                padding: 30px;
                width: 280px;
                bottom: 0;
                left: 60px;
                transform: translate(-50%, -60px);
                .close-tab {
                  float: right;
                  margin-top: 5px;
                  font-size: 18px;
                  cursor: pointer;
                  width: 15px;
                }
                .download-text {
                  font-size: 17px;
                }
                .download-sub {
                  font-size: 12px;
                  color: #a2a2a2;
                  font-weight: 600;
                  margin-top: 15px;
                }
                .download-select {
                  margin-top: 10px;
                }
                .download-button {
                  width: 115px;
                  height: 29px;
                  background-color: #009fff;
                  color: white;
                  border-radius: 2px;
                  border: none;
                  font-size: 12px;
                  margin-top: 30px;
                  float: right;
                }
                .point-down {
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  width: 10px;
                  height: 10px;
                  transform: translate(-50%, 50%) rotate(45deg);
                  background-color: white;
                }
              }
              .download-menu.opened {
                display: block;
              }
            }
          }

          .footer-image-text-info {
            display: flex;
            justify-content: space-evenly;
            margin-top: 26px;

            .image-info,
            .image-size {
              width: auto;
              flex: unset;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
