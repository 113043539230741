.container {
  padding: 30px 30px 10px 30px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    margin: 0;
    padding: 0;

    color: #a2a2a2;
  }

  input,
  textarea {
    padding: 15px;

    background-color: rgba(162, 162, 162, 0.05);

    border: none;

    transition: 0.2s;

    &::placeholder {
      color: #a2a2a2;
    }

    &:hover {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }
  }

  input {
    width: 415px;
    height: 50px;
  }

  .title {
    font-size: 12px;

    padding-bottom: 14px;
  }

  textarea {
    width: 415px;
    height: 130px;

    resize: none;
  }

  .inputs {
    color: #a2a2a2;
    font-size: 16px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    gap: 10px;
  }

  .header {
    padding: 0 0 30px 0;
    margin: 0;

    width: 100%;

    text-align: left;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      color: #333;

      font-size: 17px;
    }

    .navigate {
      padding-right: 50px;

      width: 140px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      user-select: none;

      button {
        color: #777;

        background-color: rgba(255, 255, 255, 0);

        transition: 0.1s color;

        &:hover {
          color: #333;
        }

        &.disabled {
          color: #ddd;
          pointer-events: none;

          cursor: default;
        }
      }

      .index {
        font-size: 12px;
      }
    }
  }

  .titleSection {
    padding-bottom: 30px;

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .tooltipSection {
    width: 100%;

    .tooltip {
      padding-bottom: 30px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .capacity {
    color: #333;

    font-size: 12px;

    text-align: right;

    user-select: none;

    transition: 0.05s;

    &.invalid {
      color: red;
    }
  }

  .submit {
    width: 100%;

    font-size: 12px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    gap: 16px;

    button {
      width: 132px;
      height: 30px;

      border-radius: 2px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cancel {
      background-color: rgba(162, 162, 162, 0.1);
      color: #333;
    }

    .delete {
      background-color: #313131;
      color: #fff;

      outline-color: #009fff;    
    }

    .save {
      background-color: #009fff;
      color: #fff;

      transition: 0.2s;

      &.disabled {
        background-color: rgba(0, 0, 0, 0.1);
        pointer-events: none;
      }
    }
  }

  .loadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      @keyframes loading {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      animation-name: loading;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}
