@import "src/colorVariables.scss";

.shop {
  height: 100%;
  display: flex;
  .loading {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba(162, 162, 162, 0.3);
  }
  .main-pages {
    width: 100%;
    .syncPrice {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }
  .refresh-button {
    cursor: pointer;
  }
  .info {
    padding: 20px 40px;
    width: 400px;
    background-color: $dark;
    color: white;
    .close {
      color: white;
      cursor: pointer;
    }
    .wrap-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      h1 {
        margin-top: 40px;
        word-break: break-all;
      }
      .description {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .clickable {
          cursor: pointer;
          color: $blue;
        }
      }
    }
  }
  .header {
    .left {
      .title {
        font-size: larger;
      }
    }
  }
  .wrap-tree-edit {
    position: relative;
    z-index: 1;
  }
  .infoIcon {
    cursor: pointer;
  }
  .overlay {
    background-color: #fafafa;
  }
  .overlay,
  .list {
    width: 100%;
    // padding: 0px 0px 20px 0px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .payment {
      flex-grow: 1;
    }
    .list {
      overflow: auto;
      padding: unset;
      height: calc(100% - 216px);
    }
    .select {
      > div {
        min-height: 56px;
        align-items: flex-end;
        z-index: 2;
      }
      div:first-of-type {
        z-index: 0;
      }
    }
    input:not([type="checkbox"]):not([type="radio"]),
    .div-input,
    select,
    textarea {
      padding: 24px 30px 6px 10px;
      background-color: rgba(162, 162, 162, 0.05);
      border: none;
      border-radius: 5px;
      border: 2px solid transparent;
      position: relative;
      width: 100%;
      border: 1px solid rgba(162, 162, 162, 0.2);
      &.no-label {
        padding: 6px 10px 6px 10px;
      }
    }
    hr {
      width: 100%;
      margin: 0;
    }
    .top {
      padding: 40px;
      display: flex;
      flex-direction: column;
      position: sticky;
      top: 0;
      justify-content: center;
      z-index: 2;
      background-color: #fafafa;
      gap: 30px;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          display: flex;
          align-items: center;
          font-size: 30px;
          gap: 10px;
          > div {
            cursor: pointer;
          }
          h1 {
            margin: 0;
            opacity: 0.98;
            font-weight: bold;
          }
        }
        .right-side {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
          justify-content: right;
          button {
            border: none;
            background-color: transparent;
            padding: 5px 10px;
            font-weight: 600;

            &:hover {
              opacity: 0.75;
            }

            &:disabled,
            &[disabled] {
              opacity: 0.2;
            }

            &.edit-button {
              background-color: rgba(162, 162, 162, 0.1);
              // &:hover{
              //   background-color: rgba(162, 162, 162, 0.2);
              // }
            }
            &.discard-button {
              color: $red;
              font-weight: 500;
              &:hover {
                text-decoration: underline;
              }
            }
            &.save-button {
              background-color: $blue;
              color: white;
            }
            &.open-pdf {
              background-color: white;
            }
            &.send-pdf {
              background-color: $blue;
              color: white;
            }
            &.send-payment-with-email {
              background-color: $dark;
              color: white;
            }
            &.approve {
              background-color: $green;
              color: white;
            }
            &.deny {
              background-color: $red;
              color: white;
            }
          }
        }
        .add-product,
        .save {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: $blue;
          padding: 10px 20px;
          color: white;
          font-size: 18px;
          font-weight: 600;
        }
        .cta {
          display: flex;
          align-items: center;
          gap: 20px;
          .delete {
            color: $red;
            cursor: pointer;
          }
        }
        .search {
          width: 424px;
          position: relative;
          display: flex;
          align-items: center;
          input {
            padding: 15px 30px 15px 15px;
            background-color: rgba(162, 162, 162, 0.05);
            border: none;
            border-radius: 5px;
            border: 2px solid transparent;
            position: relative;
            width: 100%;
          }
          svg {
            position: absolute;
            right: 20px;
          }
        }
        .close-product {
          cursor: pointer;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        }
        .wrap-filter {
          display: flex;
          align-items: center;
          gap: 30px;
          .filter {
            gap: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            height: 40px;
            border: 1px solid rgba(162, 162, 162, 0.1);
            border-radius: 20px;
            user-select: none;
            cursor: pointer;
            &.open {
              color: white;
              background-color: $blue;
            }
          }
          .views {
            display: flex;
            gap: 30px;
            > div {
              cursor: pointer;
            }
            &.order {
              div:first-of-type {
                color: $blue;
              }
            }
            &.list {
              div:last-of-type {
                color: $blue;
              }
            }
          }
        }
      }
      .search-section {
        &.hidden {
          display: none;
        }
      }
    }
    .content {
      background-color: unset;
    }
    .list,
    .order {
      table {
        width: 100%;
        tr:hover {
          td {
            cursor: pointer;
            background-color: rgba(162, 162, 162, 0.1);
          }
        }
        tr {
          th,
          td {
            padding: 0 20px;
            border-bottom: 1px solid rgba(162, 162, 162, 0.1);
          }
          th {
            font-size: 14px;
            font-weight: 600;
            color: #a2a2a2;
            opacity: 0.98;
            padding: 10px 20px;
            -webkit-user-select: none;
            user-select: none;
            transition: ease-in-out 0.2s;
          }

          td,
          th {
            // text-align: center;
          }
          td:first-of-type,
          th:first-of-type {
            padding: 0 0 0 10px;
          }
          th:nth-child(2) {
            text-align: left;
          }

          td {
            .image {
              // background-color: rgba(162, 162, 162, 0.1);
              min-width: 68px;
              height: 64px;
              margin: 2px 0;
              display: flex;
              align-items: center;
              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
              }
              svg {
                height: 60%;
              }
            }
            .wrap-name {
              display: flex;
              align-items: center;
              gap: 20px;
              text-align: left;
            }
          }
        }
      }
    }
    .order {
      table {
        tr {
          td:first-of-type,
          th:first-of-type {
            width: unset;
          }
          td {
            // height: 60px;
            padding: 10px 0;
            .wrap-name {
              .image {
                width: unset;
                background-color: unset;
              }
            }
          }
        }
      }
    }
  }
  .payments {
    .status {
      font-weight: 600;
      font-size: 0.9rem;
      padding: 4px 8px;
      border-radius: 2px;
      &.pending {
        background-color: $blue;
        color: white;
      }
      &.manual {
        background-color: black;
        color: white;
      }
      &.approved,
      &.done {
        background-color: $green;
        color: white;
      }
      &.denied {
        background-color: $red;
        color: white;
      }
      &.ready {
        background-color: black;
        color: white;
      }
    }
  }

	.confirm-overlay{
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 99;
		background-color: rgba(0,0,0,0.5);

		.confirm-inner{
			padding: 25px;
			margin: 15% auto;
			width: 40%;
			background-color: white;
			
			.confirm-cp{
				margin: 50px -25px -25px -25px;
				padding: 10px;
				display: flex;
				flex-direction: row;
				gap: 25px;

				.confirm-flex-filler{
					flex-grow: 1;
				}

				button{
          padding: 5px 10px;
          
					&.confirm-btn{
						border: none;
						background-color: $blue;
						color: white;
						font-weight: 600;
					}
				}
			}
		}
	}
}
