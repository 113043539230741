.general-options {
  .wrap-general-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .option-title {
    font-size: 17px;
    font-weight: 700;
    margin: 10px 0px;
    user-select: none;
    svg {
      font-size: 14px;
      margin-right: 20px;
      margin-left: 5px;
    }
    &.clickable {
      cursor: pointer;
    }
  }
  .small-label {
    font-size: 12px;
    color: $mid;
    font-weight: 600;
  }
  .top-gap {
    margin-top: 15px;
  }
  .excerpt {
    textarea {
      height: 150px;
    }
    .max-chars {
      transform: unset;
    }
  }

  .feature-image {
    margin: 0;
    height: unset;
    aspect-ratio: 16 / 9;
  }

  .encapsulate.status {
    position: relative;
    cursor: pointer;
    user-select: none;
    width: 100%;
    .right-point {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .approval-wrap {
    margin-top: 10px;
    max-width: unset;
    justify-content: flex-start;
    gap: 10px;

    > div {
      margin: 0;
      font-size: 16px;
      // margin-right: 10px;
      flex: 1 1 auto;
      text-align: center;
      background-color: rgba(162, 162, 162, 0.05);

      &:hover {
        background-color: rgba(162, 162, 162, 0.2);
      }
    }
  }
}
