@import "src/colorVariables.scss";

.module {
  &.a-media {
    // margin-top: 25px !important;
    // margin-bottom: 25px !important;
  }
}

.one-media {
  height: 300px;
  max-width: 650px;
  margin: auto;
  &.clickable {
    cursor: pointer;
  }

  .upload-box {
    height: 100%;
  }
  .image-container {
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.06);
    height: 100%;

    position: relative;
    max-height: 400px;
    .before-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 64px;
      color: $blue;
    }
    .add-media,
    .new-slide,
    .remove-page,
    .is-hero {
      position: absolute;
      bottom: 20px;
      font-size: 24px;
      padding: 5px 12px;
      border-radius: 2px;
      cursor: pointer;
      background-color: rgba($color: #ffffff, $alpha: 0.4);
      transition: ease-in-out 0.2s;
      &:hover {
        background-color: rgba($color: #ffffff, $alpha: 0.5);
      }
      bottom: 20px;
      right: 20px;
      // background-color: rgba($color: #000000, $alpha: 0.1);
      // &:hover{background-color: rgba($color: #000000, $alpha: 0.5);}
    }
    .add-media {
      color: white;
      background-color: transparent;
      left: 50%;
      top: 34%;
      bottom: unset;
      right: unset;
      transform: translate(-50%, -50%);
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 15px;
      .text {
        font-size: 14px;
        display: flex;
        text-align: center;
        justify-content: center;
      }
    }

    .react-thumbnail-generator {
      width: 100%;
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    video {
      width: 100%;
      object-fit: cover;
    }
    .missing-image {
      min-width: 300px;
      min-height: 300px;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.8;
      img {
        display: none;
      }
    }
  }
}
.a-media {
  .mediaDiv {
    > div {
      background-color: black;
    }
  }
  > div > div.one-media,
  .mediaDiv,
  .caption {
    height: unset;
    max-width: 700px;
    margin: auto;
    > div {
      // margin-left: -100px;
    }
    &.small {
      > div {
        max-width: 350px;
        &.player-wrapper {
          padding-top: calc(
            350px * 9 / 16
          ); /* Player ratio: 100 / (1280 / 720) */
        }
      }
    }
    &.medium {
      > div {
        max-width: 700px;
        &.player-wrapper {
          padding-top: calc(
            700px * 9 / 16
          ); /* Player ratio: 100 / (1280 / 720) */
        }
      }
    }
    &.large {
      width: 1440px;
      margin: auto;
      max-width: 100%;
      > div {
        margin-left: unset;
        &.player-wrapper {
          padding-top: calc(
            1440px * 9 / 16
          ); /* Player ratio: 100 / (1280 / 720) */
        }
      }
    }
    &.full {
      width: 100%;
      margin: auto;
      max-width: 100%;
      > div {
        margin-left: unset;
        &.player-wrapper {
          padding-top: calc(
            100vw * 9 / 16
          ); /* Player ratio: 100 / (1280 / 720) */
        }
      }
    }

    .image-container {
      border: unset;
      max-height: unset;
    }
  }

  // .caption {
  //     min-height: 30px;
  //     &.small {
  //         div {
  //             max-width: 350px;
  //             padding: 0;
  //         }
  //     }
  //     &.medium {
  //         max-width: 684px;
  //         min-width: unset;
  //         width: 100%;
  //         div {
  //             padding: 0;
  //         }
  //     }
  //     &.large {
  //         div {
  //             padding: 0;
  //         }
  //     }

  //     .ck-content {
  //         display: flex;
  //         align-items: center;
  //         p {
  //             width: 100%;
  //         }
  //     }
  // }
}
.a-media {
  > div.one-media {
    height: unset;
    width: 700px;
    // margin-left: calc(22.36% + 1em);
    .image-container {
      border: unset;
      max-height: unset;
    }
  }
}

.size1200 {
  .caption {
    &.large {
      div {
        padding: 0 12px;
      }
    }
  }
}
.player-wrapper {
  position: relative;
  padding-top: calc(350px * 9 / 16); /* Player ratio: 100 / (1280 / 720) */
  pointer-events: visible;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.tablet {
  .a-media {
    .large,
    .full {
      > div.player-wrapper {
        padding-top: calc(100% * 9 / 16);
      }
    }
    > div {
      > div.player-wrapper {
        margin-left: unset;
      }
    }
    .caption {
      > div {
        margin-left: unset;
      }
    }
    .medium,
    .small {
      &.one-media {
        > div {
          margin-left: 12px;
        }
      }
      .player-wrapper {
        margin-left: 12px;
      }
      &.caption {
        > div {
          margin-left: 12px;
        }
      }
    }
  }
}
.phone {
  .a-media {
    .one-media {
      width: calc(100%);
      > div {
        max-width: unset !important;
        margin-left: unset;
      }
    }
    .caption {
      > div {
        margin-left: unset;
        max-width: unset;
      }
    }
    > div {
      > div.player-wrapper {
        margin-left: unset;
        max-width: unset;
        padding-top: calc(100% * 9 / 16) !important;
      }
    }
  }
}
