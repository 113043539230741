@import "src/colorVariables.scss";
@import "src/colorVariablesDs.scss";
.edit-page {
  .page-section {
    .page-section-content {
      .page-content {
        .module {
          .vfi-carousel.disable-maxwidth {
            max-width: unset !important;
            height: 100%;

            .carousel-item {
              .one-media {
                height: 850px;
              }

              .individual-caption {
                margin-left: 20px;
              }
            }

            .left-arrow,
            .right-arrow {
              position: absolute;
              top: 50%;
              z-index: 1;
              cursor: pointer;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}
.vfi-carousel {
  .add-media,
  .remove-media,
  .is-hero {
    // color: white;
    position: absolute;
    bottom: 20px;
    font-size: 24px;
    padding: 5px 10px;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgba($color: #ffffff, $alpha: 0.1);
    transition: ease-in-out 0.2s;
    &:hover {
      background-color: rgba($color: #ffffff, $alpha: 0.5);
    }
  }
  .is-hero {
    position: absolute;
    z-index: 1;
    top: 10px;
    background-color: transparent;
    text-shadow: 0px 0px 5px white;
    height: 44px;
    right: 120px;
  }
  .add-media {
    right: 80px;
    color: $light;
  }
  .remove-media {
    right: 20px;
    color: $red;
  }

  .individual-caption {
    color: $text-color;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 20px;

    height: 30px;
    display: flex;
    align-items: center;
    // margin-left: 20px;
    // margin-right: 20px;

    .vfi-text-area.individual-text {
      width: 100%;
      min-width: none;
      .ck-content {
        padding: 0;
        background-color: transparent;
        p {
          margin: 0;
        }
      }
    }
  }
  .carousel-wrap {
    position: relative;
    .middle-top {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 28px;
      display: flex;
      z-index: 1;
      .option {
        background-color: rgba($color: #ffffff, $alpha: 0.4);
        width: 28px;
        height: 28px;
        display: grid;
        place-items: center;
        font-size: 17px;
        border-radius: 2px;
        color: black;
        margin: 0 1px;
        cursor: pointer;
        transition: ease-out 0.2s;
        &.red {
          color: $red;
        }
        &:hover {
          background-color: rgba($color: #ffffff, $alpha: 0.8);
        }
      }
    }
    .left-arrow,
    .right-arrow {
      position: absolute;
      top: 320px;
      z-index: 1;
      cursor: pointer;
      transform: translateY(-50%);
    }
    .left-arrow {
      left: 15px;
      pointer-events: visible;
    }
    .right-arrow {
      right: 15px;
      pointer-events: visible;
    }
    .top-on-carousel {
      display: flex;
      height: 42px;
      justify-content: flex-end;
      margin-top: 1px;
      .top-left-arrow,
      .top-right-arrow {
        filter: brightness(0);
        margin: 0 10px;
        cursor: pointer;
        pointer-events: auto;
        img {
          height: 28px;
          width: 12px;
        }
      }

      .top-right-arrow {
        margin-right: 5px;
      }
    }
    .carousel.slide {
      .carousel-indicators {
        position: absolute;
        bottom: 60px;
        margin: 0;
        z-index: 3;
        li {
          width: 12px;
          height: 12px;
          border: 2px solid white;
          border-radius: 14px;
          opacity: 1;
          background-color: transparent;
          position: relative;
          margin: 0 5px;
          pointer-events: visible;
        }
        li.active {
          &::before {
            content: "";
            width: 6px;
            height: 6px;
            background-color: white;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(50%, 50%);
            border: none;
            border-radius: 3px;
            color: white;
          }
        }
      }
      .one-media {
        max-width: unset;
        height: 600px;
        .image-container {
          max-height: unset;
        }
      }
    }
    .no-image {
      background-color: rgba($color: #000000, $alpha: 0.1);
      width: 100%;
      height: 600px;
      object-fit: cover;
    }

    .info {
      position: absolute;
      bottom: 90px;
      left: 40px;
      width: 70%;
      background-color: rgba($color: #000000, $alpha: 0.1);
      .vfi-input-text {
        input {
          // text-align: center;
          color: white;
          padding: 0px 10px;
          border: 0;
        }
        input:focus {
          color: black;
        }
      }
      .headline {
        margin-bottom: 2px;
        font-size: 23px;
        font-weight: bold;
        letter-spacing: 1.6px;
        line-height: 29px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        border: 0;
      }
      .excerpt {
        font-style: italic;
        font-size: 16px;
        line-height: 20px;
      }
      .headline,
      .excerpt,
      .button-text {
        input {
          &:focus {
            color: white;
          }
        }

        .carousel.slide {
          .carousel-indicators {
            position: absolute;
            bottom: 50px;
            margin: 0;
            li {
              width: 12px;
              height: 12px;
              border: 2px solid white;
              border-radius: 14px;
              opacity: 1;
              background-color: transparent;
              position: relative;
              margin: 0 5px;
              pointer-events: visible;
            }
            li.active {
              &::before {
                // content: "<div></div>";
                content: "";
                width: 6px;
                height: 6px;
                background-color: white;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(50%, 50%);
                border: none;
                border-radius: 2px;
                color: white;
              }
            }
          }
          .one-media {
            max-width: unset;
            height: 600px;
            .image-container {
              max-height: unset;
            }
          }
        }
      }
      .button-check-wrap {
        position: absolute;
        margin-left: 10px;
        background-color: rgba($color: #ffffff, $alpha: 0.2);
        .button-check {
          height: 15px;
        }
        & > span {
          color: white;
        }
      }
      .button-wrap {
        display: flex;
        align-items: flex-end;
        .button {
          margin-top: 30px;
          margin-left: 10px;
          background-color: rgba(255, 255, 255, 0.4);
          border: 1px solid white;
          border-radius: 2px;
          width: 250px;
          min-width: 250px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          &.active {
            pointer-events: all;
            cursor: pointer;
            * {
              pointer-events: none;
            }
          }
          .button-text {
            input {
              text-align: center;
              font-size: 15px;
              font-weight: bold;
            }
          }
          .link-to {
            position: absolute;
            right: 8px;
            background-color: rgba($color: $light, $alpha: 0.8);
            border-radius: 2px;
            cursor: pointer;
            font-size: 17px;
            width: 28px;
            height: 28px;
            display: grid;
            place-items: center;
            z-index: 1;
            transition: ease-out 0.2s;
            &:hover {
              background-color: $blue;
              color: white;
            }
          }
        }
        .button-direct-wrap {
          // position: absolute;
          bottom: 0;
          right: 0;
          margin-left: 20px;
          white-space: nowrap;
          display: flex;
          align-items: center;
          height: min-content;
          .page-select {
            color: white;
            display: flex;
            justify-content: left;
            margin-right: 5px;

            .page-select-button {
              padding: 2px 10px;
              background-color: rgba($color: #ffffff, $alpha: 0.2);
              display: inline-block;
              cursor: pointer;
              user-select: none;
              border-radius: 2px;
              border: 1px solid white;
              transition: ease-in-out 0.2s;
              &.active {
                // color: $green;
                // background-color: rgba($color: $green, $alpha: 0.2);
                // border: 1px solid $green;
                color: white;
                background-color: rgba($color: green, $alpha: 1);
                // border: 1px solid limegreen;
              }
              &:hover {
                background-color: rgba($color: #ffffff, $alpha: 0.4);
              }
              p {
                margin: 0;
              }
            }
          }
          & > span {
            color: white;
          }
          .button-direct {
            display: inline-block;
            input {
              background-color: rgba($color: #ffffff, $alpha: 0.2);
              &:focus {
                background-color: white;
              }
            }
          }
        }
      }
    }
    .real-carousel-wrap {
      height: 600px;
      .real-carousel {
        // display: flex;
        white-space: nowrap;
        position: absolute;
        width: 100%;
        transition: ease-in-out 0.5s;
        .one-real-wrap {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          position: relative;
          margin-right: 20px;
          transition: ease-in-out 0.5s;
          .one-media {
            max-width: unset;
            height: 600px;
            pointer-events: auto;
            user-select: none;
            .image-container {
              max-height: unset;
            }
          }
          .individual-caption {
            position: absolute;
            bottom: 0;
            background-color: white;
            width: 100%;
            .ck-content {
              p {
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
  }
  &.slider_with_highlight {
    .info {
      display: none;
    }
    .wrap-highlight-boxes {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      width: 1280px;
      height: 80%;
      min-height: 480px;
      gap: 64px;
      justify-content: center;
      > div {
        width: 30%;
        height: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        position: relative;
        > div {
          width: 100%;
          .ck-content {
            p {
              margin-bottom: 0 !important;
              font-weight: 300;
            }
          }
        }
        .button {
          background-color: $mainColor;
          text-align: center;
          width: 80%;
          padding: 10px 10px;
          color: white;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .link {
            position: absolute;
            right: 10px;
            opacity: 0.6;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            font-size: 17px;
          }
          .link:hover {
            opacity: 1;
            background-color: rgba($color: #000000, $alpha: 0.1);
          }
        }

        .remove {
          right: 2px;
          position: absolute;
          width: unset;
          top: 2px;
          opacity: 0.6;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          cursor: pointer;
          font-size: 17px;
        }
        .remove:hover {
          opacity: 1;
          background-color: rgba($color: #000000, $alpha: 0.1);
        }
      }
      .add_new_highlight_box {
        justify-content: center;
        background-color: rgba($color: #ffffff, $alpha: 0.4);
        cursor: pointer;
      }
      .add_new_highlight_box:hover {
        background-color: rgba($color: #ffffff, $alpha: 0.8);
      }
    }
  }
  .on-hover-caption {
    display: flex;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    .the-i {
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      font-family: "Libre Baskerville";
      font-style: italic;
      border: 1px solid white;
      border-radius: 20px;
      color: white;
    }
    .on-hover-text {
      position: unset;
      // position: absolute;
      // right: 40px;
      white-space: nowrap;
      color: white;
      // font-size: 14px;
      // line-height: 17px;
      min-width: 90px;

      font-family: "Source Sans Pro";
      font-style: italic;
      // height: 22px;
      font-size: 17px;
      letter-spacing: 0;
      line-height: 22px;
      text-align: right;
      margin-right: 20px;

      p {
        margin-bottom: 0 !important;
      }
    }
  }
  .thumb-wrap {
    position: relative;
    display: flex;
    .left-arrow,
    .right-arrow {
      width: 50px;
      height: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 12px;
        height: 28px;
        filter: brightness(0%);
      }
    }

    .right-arrow {
      right: 0;
    }
    .thumb-images {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .one-media {
        width: 150px;
        height: 75px;
        margin: 0 10px;
      }
    }
    .left-arrow,
    .right-arrow,
    .one-media {
      pointer-events: auto;
      cursor: pointer;
    }
  }
  .wall-grid {
    max-width: 1440px;
    margin: auto;
    .grids-wrap {
      .item1 {
        grid-area: one;
      }
      .item2 {
        grid-area: two;
      }
      .item3 {
        grid-area: three;
      }
      .item4 {
        grid-area: four;
      }
      .item5 {
        grid-area: five;
      }
      .grid-content {
        position: relative;
      }
      .one-media {
        width: 100%;
        max-width: unset;
        height: 600px;
        .image-container {
          max-height: unset;
        }
      }
      .grids {
        margin-top: 3px;
        display: grid;
        grid-template-areas: "one";
        // grid-template-columns: calc(50% - 1.5px) calc(50% - 1.5px);
        grid-gap: 3px;
        &.amount-2 {
          grid-template-areas: "one two";
        }
        &.amount-3 {
          grid-template-areas:
            "one two"
            "three three";
        }
        &.amount-4 {
          grid-template-areas:
            "one one one two two"
            "three three four four four";
        }
        &.amount-5 {
          grid-template-areas:
            "one one one two two"
            "three three three three three"
            "four four five five five";
        }

        .grid-content {
          // .remove-media {
          //   right: 50%;
          //   transform: translateX(50%);
          //   top: 20px;
          //   width: 28px;
          //   height: 28px;
          //   font-size: 17px;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          // }
          &:hover {
            &:not(.active) {
              .top-choices {
                opacity: 1;
                pointer-events: all;
              }
            }
          }
          &.active {
            cursor: pointer;
            pointer-events: all;
          }
          .top-choices {
            position: absolute;
            top: 10px;
            left: 0;
            right: 0;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            pointer-events: none;
            transition: ease-in-out 0.2s;
            .choice {
              margin: 1px;
              color: white;
              width: 28px;
              height: 28px;
              background-color: rgba($color: #000000, $alpha: 0.2);
              text-align: center;
              border-radius: 2px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 17px;
              transition: ease-out 0.2s;
              &:hover {
                background-color: rgba($color: #ffffff, $alpha: 0.5);
              }
              &.change-page {
                &:hover {
                  background-color: rgba($color: $blue, $alpha: 1);
                  color: white;
                }
              }
            }
            .remove {
              color: red;
            }
          }
          .medium-text {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            margin-bottom: 24px;

            .headline {
              font-size: 21px;
              font-family: "Libre Baskerville";
              padding: 0;
              input {
                padding: 4px;
              }
            }
            .excerpt {
              font-size: 16px;
              input {
                padding: 4px;
              }
            }
            input {
              text-align: center;
              color: white;
            }
          }
          img {
            position: absolute;
          }
        }
      }
    }
    .flex-wrap {
      display: flex;
      .flex-content {
        flex-grow: 1;
        position: relative;
        .one-media {
          height: 600px;
          // min-width: 300px;
          display: flex;
          max-width: unset;
          padding: 3px;
          .upload-box {
            width: 100%;
            .image-container {
              max-height: unset;
              display: flex;
              img {
                flex-grow: 1;
              }
            }
          }
        }
        .remove-media {
          top: 10px;
          right: 60px;
          bottom: unset;
          opacity: 0;
        }
        &:hover .remove-media {
          opacity: 1;
        }
      }
    }
    .expand-grid {
      color: white;
      background-color: black;
      position: absolute;
      bottom: 10px;
      right: 10px;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      pointer-events: auto;
      user-select: none;
      .text {
        margin-right: 20px;
      }
    }
    .add-media {
      bottom: 80px;
      right: 20px;
      background-color: rgba($color: #000000, $alpha: 0.1);
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.5);
      }
    }
  }
  .no-image {
    height: 600px;
    width: 100%;
    background-color: black;
    opacity: 0.8;
  }
}
.phone {
  .vfi-carousel {
    .thumb-wrap {
      .thumb-images {
        width: 100%;
        .one-media {
          height: 85px;
          margin: 0 2px;
        }
      }
    }
    .carousel-wrap {
      .info {
        bottom: 80px;
      }
      .carousel.slide {
        .carousel-indicators {
          bottom: 50px;
        }
        .carousel-inner {
          .carousel-item {
            .one-media {
              height: 600px;
            }
          }
        }
      }
    }

    .wall-grid {
      .grids-wrap {
        .one-media {
          height: 250px;
        }
      }
    }

    &.slider_with_thumb,
    &.slider_carousel {
      .info {
        bottom: 60px !important;
      }
    }
  }
}
