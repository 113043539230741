@import "src/colorVariables.scss";
.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  .headline.vfi-div-input {
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 40px;
    width: 100%;
    font-size: 24px;
    background-color: transparent;
    text-align: center;
    letter-spacing: 1px;
    font-weight: bold;
    color: $text-color;
    input {
    }
  }
  .email {
    position: relative;
    background-color: white;
    border-radius: 2px;

    &.vfi-input-text {
      width: 525px;
      input {
        font-size: 20px;
        line-height: 33px;
        height: 60px;

        padding-right: 110px;
      }
      .right-icon {
        display: none;
      }
    }

    .email-btn {
      display: flex;
      align-items: center;

      color: $blue;
      font-family: "Source Sans Pro";
      font-size: 16px;
      font-style: italic;
      letter-spacing: 0.24px;
      line-height: 20px;
      text-align: right;

      svg {
        height: 20px;
        width: 14px;
        color: $blue;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 8px;
      }
    }

    .custom-under {
      position: absolute;
      bottom: -39px;
      right: 15px;

      .show-more {
        color: $blue;
        font-size: 13px;
        line-height: 17px;
        text-align: right;
        border: none;
        padding: 0;
        // cursor: pointer;
      }
    }
  }
}
.newsletter.edit-module {
  height: 630px;
  width: 430px;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  .top {
    background-color: unset;
  }
  .content {
    width: 100%;
    padding-top: 10px;
    .news-types-list.search {
      margin: auto;
      width: calc(100% - 40px);
    }
    .add-option {
      margin-top: 15px;
      margin-left: 15px;
      cursor: pointer;
      color: $blue;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }
    .newsletter-types {
      margin: 15px;
      .newsletter-type {
        display: flex;
        padding: 8px 0;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
        justify-content: space-evenly;
        > label {
          display: flex;
          align-items: center;
          width: 80%;
          margin: 0;
          cursor: pointer;
          .text {
            margin-left: 20px;
          }
          label {
            margin: 0;
            height: 20px;
          }
        }
        .trash {
          margin-left: 10px;
          cursor: pointer;
          transition: ease-out 0.2s;
          color: $red;
          &:hover {
            color: black;
          }
        }
      }
    }
  }
}
.phone {
  .newsletter {
    .email.vfi-input-text {
      width: calc(100% - 30px);
    }
  }
}
